<template>
	<div class="page-container page_regi">
		<!-- <RegiHead /> -->
		<div class="regi-bd change_bd">
			<div class="regi_name">{{$t('chTxt')}}</div>
			<el-form ref="form" class="regi-from" :rules="rules" :model="form">
				<el-form-item prop="oldPassword">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt9')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.oldPassword" :placeholder="$t('chTxt9')" :type="pass?'password' : 'text'">
							</el-input>
							<!-- <div class="regi_eye" @click="eye">
								<i class="iconfont icon-eye-close" v-if="Eye"></i>
								<i class="iconfont icon-eye" v-else></i>
							</div> -->
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="newPassword">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt10')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.newPassword" :placeholder="$t('chTxt10')" :type="pass2?'password' : 'text'">
							</el-input>
							<!-- <div class="regi_eye" @click="eye2">
								<i class="iconfont icon-eye-close" v-if="Eye2"></i>
								<i class="iconfont icon-eye" v-else></i>
							</div> -->
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="checkPassword">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt6')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.checkPassword" :placeholder="$t('chTxt6')" :type="pass3?'password' : 'text'">
							</el-input>
							<!-- <div class="regi_eye" @click="eye3">
								<i class="iconfont icon-eye-close" v-if="Eye3"></i>
								<i class="iconfont icon-eye" v-else></i>
							</div> -->
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="code">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt11')}}</div>
						<div class="regi_gr_b flex flex-ac">
							<el-input v-model="form.code" :placeholder="$t('chTxt11')">
								<span v-if="!disabled" slot="suffix" class="regi_input_text regi_send"  @click="sendCode">{{$t('code2')}}</span>
								<span v-else  class="regi_input_text" slot="suffix">{{count}}</span>
							</el-input>
							
							<!-- <el-button class="btn btn_yzm" type="primary" @click="sendCode" :disabled="disabled">
								<span v-if="disabled">{{count}} s</span>
								<span v-if="!disabled">{{$t('code2')}}</span>
							</el-button> -->
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<div class="regi_group chan_btn flex flex-ac flex-between">
						<el-button class="btn btn_reset" size="large" type="primary" @click="resetForm('form')">{{$t('reset')}}
						</el-button>
						<el-button size="large" class="btn" type="primary" @click="submitForm('form')">{{$t('save')}}</el-button>
					</div>
				</el-form-item>
			</el-form>
			<!-- <div class="regi_tips">Copyright ©2024 Alcex All rights reserved.</div> -->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import {
		securitySet
	} from '@/api/api/user'
	import {
		passCode,
		updatePass
	} from '@/api/api/user'
	import RegiHead from '@/components/RegiHead.vue'
	export default {
		components: {
			RegiHead,
		},
		data() {
			const validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error(this.$t("chtip2")));
				} else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
					callback(new Error(this.$t("chtip2")));
				} else {
					callback();
				}
			};
			const validatePassCheck = (rule, value, callback) => {
				if (value === "") {
					callback(new Error(this.$t("chtip3")));
				} else if (!/([a-zA-Z0-9]){6,18}/.test(value)) {
					callback(new Error(this.$t("chtip3")));
				} else if (value !== this.form.newPassword) {
					callback(new Error(this.$t("chtip3")));
				} else {
					callback();
				}
			};
			return {
				form: {

				},
				rules: {
					oldPassword: [{
						required: true,
						type: "string",
						min: 6,
						message: this.$t("chtip"),
						trigger: "blur"
					}],
					newPassword: [{
							required: true,
							type: "string",
							min: 6,
							message: this.$t("chtip2"),
							trigger: "blur"
						},
						{
							validator: validatePass,
							trigger: "blur"
						}
					],
					checkPassword: [{
							required: true,
							type: "string",
							min: 6,
							message: this.$t("chtip3"),
							trigger: "blur"
						},
						{
							validator: validatePassCheck,
							trigger: "blur"
						}
					],
					code: [{
						required: true,
						message: this.$t("chTxt3"),
						trigger: "blur"
					}]
				},
				count: 60,
				disabled: false,
				Eye: true,
				Eye2: true,
				Eye3: true,
				pass: true,
				pass2: true,
				pass3: true,
				set:{}
			};
		},
		mounted() {
			this.getAccount()
		},
		methods: {
			getAccount(){
				securitySet().then(res => {
					if (res.code == 0) {
						this.set = res.data
						console.log(this.set)
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						console.log(1)
						updatePass({
							code: this.form.code,
							newPassword: this.form.newPassword,
							oldPassword: this.form.oldPassword
						}).then(res => {
							if (res.code == 0) {
								this.$message.success(this.$t('save_success'));
								this.$router.push('/admin')
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			sendCode() {
				passCode().then(res => {
					if (res.code == 0) {
						let waitTime = 60;
						this.disabled = true
						let clock = window.setInterval(() => {
							this.count = waitTime
							waitTime--;
							if (waitTime <= 0) {
								waitTime = 60;
								this.disabled = false
								window.clearInterval(clock);
							}
						}, 1000);
					} else {
						this.$message.error(res.message);
					}
				})
			},
			eye() {
				this.Eye = !this.Eye
				this.pass = !this.pass
			},
			eye2() {
				this.Eye2 = !this.Eye2
				this.pass2 = !this.pass2
			},
			eye3() {
				this.Eye3 = !this.Eye3
				this.pass3 = !this.pass3
			}
		}
	}
</script>
